import { ServiceDTO } from "./ServiceDTO";
import {AccountGroupScheduleDTO} from "./AccountGroupScheduleDTO";
import { BotPlatformDTO } from "./BotPlatformDTO";
import {ProxyGroupReducedDTO} from "./ProxyGroupReducedDTO";
import {QueueDTO} from "./QueueDTO";

export class AccountGroupDTO {
  id:number;
  name: string;
  service:ServiceDTO;
  accountIds:number[]
  schedules: AccountGroupScheduleDTO[];
  dateCreated: Date;
  dateModified: Date;
  botPlatform?: BotPlatformDTO;
  perRegionScheduling?: boolean;
  perServiceScheduling?: boolean;
  reverseProxyGroup?: ProxyGroupReducedDTO;
  queue?: QueueDTO;
  resetPassword: boolean;
  autoAssignFingerprints: boolean;

  constructor(json: AccountGroupDTO) {
    this.id =json.id;
    this.name = json.name;
    this.schedules = json.schedules?.map((schedule: any) => new AccountGroupScheduleDTO(schedule)) ?? [];
    this.service = new ServiceDTO(json.service);
    this.accountIds=json.accountIds;
    this.dateCreated = new Date(json.dateCreated);
    this.dateModified = new Date(json.dateModified);
    this.botPlatform = json.botPlatform ? new BotPlatformDTO(json.botPlatform) : undefined;
    this.perRegionScheduling = json.perRegionScheduling;
    this.perServiceScheduling = json.perServiceScheduling;
    this.reverseProxyGroup = json.reverseProxyGroup ? new ProxyGroupReducedDTO(json.reverseProxyGroup) : undefined;
    this.queue = json.queue ? new QueueDTO(json.queue) : undefined;
    this.resetPassword = json.resetPassword;
    this.autoAssignFingerprints = json.autoAssignFingerprints;
  }
}
